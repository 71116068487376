import React, { Component } from 'react';
import lineClamp from 'line-clamp';
import globe from './globe-icon.png';
import online from './online.svg';
import link from './link.svg';
import copy from 'clipboard-copy';

export default class PropertyCard extends Component {
  constructor(props) {
    super(props);
    this.renderUpcommingEvent = this.renderUpcommingEvent.bind(this);
  }

  componentDidMount() {
    document.querySelectorAll('.event-about').forEach(el => {
      if (this.props.upcommingEvent) {
        lineClamp(el, 2);
      } else {
        lineClamp(el, 3);
      }
    });
  }

  renderUpcommingEvent() {
      function convertTime12to24([hour, minute], period) {
        let newHour;
        if (hour === 12 && period === 'PM') {
          newHour = 0;
        } else if (period === 'PM') {
          newHour = hour + 12;
        } else {
          newHour = hour
        }
        return [newHour, minute];
      }

      const { 
        eventTime: UTCEventTime,
        id: eventId, 
        title: eventTitle
      } = this.props.upcommingEvent;

      const eventLink = `https://www.creep3d.com/Events/Event/?eventId=${eventId}`;

      const [date, time, period] = UTCEventTime.split(' ');
      const [month, day, year] = date.split('/').map(part => parseInt(part, 10));
      const [hour, minute] = convertTime12to24(
          time.split(':').map(part => parseInt(part, 10)), 
          period
        );

      const eventTime = new Date(year, month - 1, day, hour, minute);
      const offset = new Date().getTimezoneOffset();
      eventTime.setMinutes(eventTime.getMinutes() - offset);
      const ongoing = eventTime < Date.now();

      const options = {
        weekday: 'short', month: 'short', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
        hour12: true,
      };
      const displayTime = new Date(eventTime).toLocaleString('en-US', options);
      
      return(
        ongoing 
        ?
        <a className="event ongoing" href={eventLink}>
          <b>IN PROGRESS: </b>
          {`${eventTitle}`}
        </a>
        :
        <a className="event upcomming" href={eventLink}>
          <b>Next Event: </b>
          {`${displayTime} - ${eventTitle}`}
        </a>
      );
    }

  render() {
    const cdnBase = 'https://cdn.redlightcenter.com/AdServer/ServerUIVideos';

    const mediaURL = this.props.videoFileName
    ? `${cdnBase}/${this.props.id}/${this.props.videoFileName}`
    : `https://creep3d.com/events/Image.ashx?mediaId=${this.props.mediaId}`;

    return(
      <li itemnumber={this.props.itemNumber}>
        <div className="media-container">
          { this.props.videoFileName 
            ? <video controls><source src={mediaURL} /></video>
            : <img src={mediaURL} alt={`Screenshot of ${this.props.title}`} />
          }
        </div>
        <div className="details">
          <header>
            <a href={this.props.url} alt={`Open ${this.props.title} in Virtual World Web client.`}>
              <h2>{this.props.title}</h2>
            </a>
            <img className="copy-icon" src={link} 
              alt="Copy link to clipboard" onClick={() => copy(this.props.url)}  />
            <div className="world">
              <img src={globe} alt="Globe icon" />
              <button type="button" onClick={() => this.props.onQuery('world', this.props.worldId)}>
                {this.props.world}
              </button>
            </div>
            <div className="online">
              <img src={online} alt="Online icon" />
              <span>{this.props.onlineCount || 0}</span>
            </div>
          </header>
          <p className="event-about">{this.props.about}</p>
          { this.props.upcommingEvent && this.renderUpcommingEvent() }
        </div>
      </li>
    );
  }
}