import React from 'react';
import ResultHeader from './ResultHeader';
import PropertyCard from './PropertyCard';
import creep3d from './creep3d';

export default class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      properties: [], 
      showing: [], 
      page: 1, 
      perPage: this.props.uvCompatible ? 4 : 10 
    };
    this.onPaginate = this.onPaginate.bind(this);
    this.fetchMoreProperties = this.fetchMoreProperties.bind(this);
    this.newOnPaginate = this.newOnPaginate.bind(this);
    this.updateHash = this.updateHash.bind(this);

    this.updateHash();
  }

  componentDidMount() { this.newOnPaginate() }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.updateHash();
      this.setState({ page: 1, showing: [], properties: [] }, this.newOnPaginate);
    }
  }

  updateHash() {
    let hash;
    if (this.props.type === 'category') {
      hash = '/c/';
      if (this.props.parentCategory) {
        hash = hash + this.props.parentCategory.name + '/' + this.props.name;
      } else {
        hash = hash + this.props.name;
      }
    } else if (this.props.type === 'world') {
      hash = '/d/';
      hash += this.props.id;

    } else if (this.props.type === 'search') {
      hash = '/q/'
      hash += this.props.id;
    }
    window.location.hash = hash;
  }

  // per page
  // page
  // properties.length

  // load more -- always five pages ahead
  // page * per page > properties.length - (per page * 5)

  fetchMoreProperties(perLoad = 50) {
    return new Promise((resolve, reject) => {
      const { propertyIds } = this.props;
      const { properties } = this.state;

      if (propertyIds.length > properties.length) {
        console.log(`Getting range ${properties.length} to ${properties.length + perLoad}`);
        creep3d.getPropertyDetails(
          propertyIds.slice(properties.length, properties.length + perLoad)
          )
        .then(properties => {
          this.setState({ properties: this.state.properties.concat(properties) }, resolve)
        })
        .catch(reason => reject(reason));

      } else {
        console.error('No more properties to load.');
      }
    })
  }

  newOnPaginate(pages = 0) {
    // number of pages to load ahead
    const runway = 5;

    const { page: currentPage, perPage, properties } = this.state;
    const { propertyIds } = this.props;
    const newPage = currentPage + pages || 1;
    const offset = (newPage - 1) * perPage;

    // fetch more if needed
    if ((currentPage * perPage > properties.length - (perPage * runway)) && propertyIds.length > properties.length) {
      console.log('Getting new properties');
      this.fetchMoreProperties()
      .then(() => {
        this.setState({ 
          showing: this.state.properties.slice(offset, offset + perPage),
          page: newPage
        });
      })
      .catch(reason => console.error(reason));
    } else {
      console.log('Displaying a new page');
      this.setState({ 
        showing: this.state.properties.slice(offset, offset + perPage),
        page: newPage
      });
    }
  }

  onPaginate(pages = 0) {
    const { page: currentPage, perPage } = this.state;
    const { propertyIds } = this.props;
    const newPage = currentPage + pages || 1;
    const offset = (newPage - 1) * perPage;

    creep3d.getPropertyDetails(propertyIds.slice(offset, offset + perPage))
    .then(properties => this.setState({ properties, page: newPage }) )
    .catch(reason => console.error(reason));
  }

  render() {
    return(
      <section className="results">
        <ResultHeader 
          {...this.props} 
          page={this.state.page}
          perPage={this.state.perPage}
          onPaginate={this.newOnPaginate}
          onQuery={this.props.onQuery}
        />
        <ol>
          {this.state.showing.map((details, i) => 
            <PropertyCard key={details.id} {...details} itemNumber={(this.state.page - 1) * this.state.perPage + i + 1} onQuery={this.props.onQuery} />)
          }
        </ol>
      </section>
    );
  }
}