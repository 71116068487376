import React from 'react';
import creep3d from './creep3d';

function SearchResult({ text, handleSearch }) {
  return(
    <div className="result" onClick={() => handleSearch(text)}>
      <ion-icon name="search"></ion-icon>
      <span>{ text }</span>
    </div>
  );
}

export default class Search extends React.Component {
  constructor(props) {
    super(props);
    this.loadResults = this.loadResults.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = { value: '', terms: [], results: [] };
  }

  componentDidMount() {
    creep3d.getTopTerms()
    .then(terms => this.setState({ terms }))
    .catch(reason => console.error(reason));
  }

  loadResults(query = null) {
    const results = query 
    ? 
    this.state.terms.filter(term => term.startsWith(query))
                    .slice(0, 10)
    :
    null;
    this.setState({ results });
  }

  handleChange(ev) {
    const value = ev.target.value;
    this.setState({ value }, () => {
      this.loadResults(value);
    });
  }

  handleSearch(query) {
    this.setState({ results: [] });
    this.props.handleSearch(query);
  }

  handleKeyPress({ key }) {
    if (key === 'Enter') {
      this.handleSearch(this.state.value);
    }
  }

  render() {
    return (
      <div 
      className="search-container" 
      onMouseEnter={() => this.setState({hovered: true}) }
      onMouseLeave={() => this.setState({hovered: false}) }
      >
        <div className="search-box">
          <input 
            type="search" 
            name="search"
            value={ this.state.value }
            onChange={ this.handleChange }
            onKeyPress={ this.handleKeyPress }
            onBlur={() => !this.state.hovered && this.setState({ results: [] })}
          />
          <button 
            type="button" 
            className="search"
            onClick={() => this.handleSearch(this.state.value)}
          >
            <ion-icon name="search"></ion-icon>
          search
         </button>
        </div>
        {
          this.state.results &&
          <div className="results">
            { this.state.results.map((result, i) => <SearchResult handleSearch={this.handleSearch} text={result} key={result + i} />)}
          </div>
        }
      </div>
    );
  }
}
