import React from 'react';
import CategoryList from './CategoryList';
import Results from './Results';
import Search from './Search';
import LoadingIndicator from './LoadingIndicator';
import creep3d from './creep3d';

import vwwLogo from './VWW-logo.svg';

import './App.css';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      categories: null, 
      query: null,
      uvCompatible: window.location.href.includes('uv-compatible=1'),
      loading: true
    };
    this.onQuery = this.onQuery.bind(this);
    this.renderCategories = this.renderCategories.bind(this);
    this.renderResults = this.renderResults.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.decodeHtml = this.decodeHtml.bind(this);

    

    const hash = decodeURIComponent(window.location.hash);
    if (hash) {
      try {
      /*
        group 1: result type, one of dcq
        group 2: result param
      */
        const hashRegex = /#\/([dcq])\/(.+)/gm;
        // ['d', 'Adult']
        const {1: type, 2: param} = [...hash.matchAll(hashRegex)][0];
        if (type === 'd') {
          this.state.defaultQuery = ['world', param];
        } else if (type === 'c') {
          const path = param.split('/');
          this.state.defaultQuery = ['category', path[path.length - 1]];
        } else if (type === 'q') {
          this.state.defaultQuery = ['search', param];
        } else {
          throw new Error('Invalid query string format: expected type d, c, or q, got ' +  type);
        }
      } catch (reason) {
        console.error(reason);
      }
    } else {
      this.state.page = 'categories';
    }
  }

  componentDidMount() {
    if (this.state.defaultQuery) {
      const [type, param] = this.state.defaultQuery;
      if (type === 'category') {
        this.onQuery('categoryList')
        .then(() => {
          if (this.state.categories) {
            const category = this.state.categories.find(c => {
              if (c.name === param) return c;
              const sub = c.subcategories.find(c => c.name === param);
              if (sub) return sub;
              return false;
            });
            if (category) {
              category.name === param ? this.onQuery('category', category.id) : this.onQuery('category', category.subcategories.find(s => s.name === param).id)
            }
          }
        })
      } else {
        this.onQuery(type, param);
      }
    } else {
      this.onQuery('categoryList');
    }
  }

  // horrible error handling
  onQuery(kind, id) {
    this.setState({ loading: true });
    return new Promise((resolve, reject) => {
      if (kind === 'categoryList') {
        creep3d.getCategoryList()
        .then(list => {
          this.setState({ page: 'categories', categories: list }, () => {
            this.setState({ loading: false });
            resolve(true);
          })
        })
        .catch(reason => console.log(reason));
      } else {
        creep3d.getPropertyIds(kind, id)
        .then(res => {
          this.setState({ page: 'results', query: res }, () => {
            this.setState({ loading: false });
            resolve(true);
          })
        })
        .catch(reason => console.log(reason));
      }
    });
  }

  // exists elsewhere; consolidate
  decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    console.log(html, txt.value);
    return txt.value;
  }

  handleSearch(query) {
    creep3d.getPropertyIds('search', query)
    .then(res => this.setState({ page: 'results', query: res }))
    .catch(reason => console.error(reason));
  }

  renderCategories() {
    return <CategoryList categories={this.state.categories} onQuery={this.onQuery} />;
  }

  renderResults() {
    return <Results {...this.state.query} uvCompatible={this.state.uvCompatible} onQuery={this.onQuery}  />;
  }

  render() {
    return(
        <div className={this.state.uvCompatible ? 'App uv' : 'App'}>
        { !this.state.uvCompatible &&
            <header className="site-header">
            <div id="vww-logo">
              <img src={vwwLogo}
                   alt="Virtual World Web" />
              <p>Directory</p>
            </div>
            { !this.state.uvCompatible &&
            <div className="header-btns">
              <a className="header-btn" target="_blank" rel="noopener noreferrer" href="https://www.utherverse.com">
                <div className="label">
                  <div>Download</div>
                  <div>3D Browser Here</div>
                </div>
              </a>

              <a className="header-btn" target="_blank" rel="noopener noreferrer" href="https://www.utherverse.com/net/vww/manage.aspx">
                <div className="label">
                  <div>Manage</div>
                  <div>Properties</div>
                </div>
              </a>

              <a className="header-btn" target="_blank" rel="noopener noreferrer" href="http://decorators.utherverse.com/">
                <div className="label">
                  <div>Decorators</div>
                  <div>Directory</div>
                </div>
              </a>
            </div>
          }
          </header>
        }
        <div className={this.state.uvCompatible ? 'main uv' : 'main'}>
        { this.state.loading && <LoadingIndicator /> }
        { this.state.page === 'categories' && <Search handleSearch={this.handleSearch} /> }
          <nav>
          { this.state.page === 'categories' && this.state.categories
            ? this.renderCategories()
            : this.state.page === 'results' && this.state.query
            ? this.renderResults()
            : null
          }
          </nav>
        </div>
      </div>
    );
  }
}