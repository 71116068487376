import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (window.location.href.includes('uv-compatible=1')) {
  const width = '832px';
  const height = '550px';
  document.documentElement.style.width = width;
  document.documentElement.style.height = height;

  document.body.style.width = width;
  document.body.style.height = height;

  const index = document.getElementById('root');
  index.style.width = width;
  index.style.height = height;
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
