const base = 'https://www.creep3d.com/Directory/Data/';

// Overwrites native 'children' prototype.
// Adds Document & DocumentFragment support for IE9 & Safari.
// Returns array instead of HTMLCollection.
;(function(constructor) {
  if (constructor &&
    constructor.prototype &&
    constructor.prototype.children == null) {
    Object.defineProperty(constructor.prototype, 'children', {
      get: function() {
        let i = 0, node, nodes = this.childNodes, children = [];
        // eslint-disable-next-line
        while (node = nodes[i++]) {
          if (node.nodeType === 1) {
            children.push(node);
          }
        }
        return children;
      }
    });
  }
})(window.Node || window.Element);

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

function parseXMLEndpoint(url) {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.onload = () => resolve(req.responseXML);
    req.onerror = (error) => reject(error);

    req.open('GET', url);
    req.send();
  });
}

// xml responses are encoded (&quot, etc.).
// clever DOM API way to decode them:
function getAndDecodeAttribute(el, attribute) {
  const txt = document.createElement('textarea');
  txt.innerHTML = el.getAttribute(attribute);
  return txt.value;
}

function getAttributes(el) {
  const attributes = el.attributes;
  const obj = {};
  for (let i = 0; i < attributes.length; i++) {
    const attr = attributes[i];
    obj[attr.nodeName] = attr.nodeValue;
  }
  return attributes;
}

export default {
  getTopTerms: function() {
    return new Promise((resolve, reject) => {
      parseXMLEndpoint(base + 'Default.ashx?v=1.18&type=6')
      .then(xml => {
        try {
          const terms = xml.querySelector('Data').textContent.split('|');
          resolve(terms);
        } catch (error) {
          reject('Error parsing XML for Top Terms: ' + error);
        }
      })
      .catch(error => reject('Could not fetch Top Terms: ' + error));
    });
  },

  getCategoryList: function() {
    return new Promise((resolve, reject) => {
      parseXMLEndpoint(base)
      .then(xml => {
        try {
          // xml node list → Array
          const categories = [];
          for (let el of xml.getElementsByTagName('Category')) {
            const obj = {};
            obj.id = el.getAttribute('catId');
            obj.name = el.getAttribute('Name');
            console.log(obj.name);
            obj.displayPath = el.getAttribute('FullName');
            if (el.hasAttribute('parentThemeId')) {
              obj.parent = el.getAttribute('parentThemeId');
            }
            categories.push(obj);
          }

          // create tree
          const categoryTree = [];
          categories.filter(category => !category.parent)
            .forEach(parent => {
              const { id, displayPath } = parent;
              const name = decodeHtml(parent.name); // here
              const subcategories = categories.filter(category => {
                return category?.parent === parent.id 
              });
              categoryTree.push({ id, name, displayPath, subcategories });
            });

          resolve(categoryTree);
        } catch (error) {
          reject('Error parsing XML for Category List.' + error);
        }
      })
      .catch(error => reject('Could not fetch Category List.'));
    });
  },

  getPropertyIds: function(type, id) {
    return new Promise((resolve, reject) => {
      if (type !== 'category' && type !== 'world' && type !== 'search') {
        reject(
          `getPropertyIds: got type ${type}, expected: category, world, search`
        );
      }

      const res = { id, type };

      parseXMLEndpoint(base + `?type=${type}&value=${id}`)
      .then(xml => {
        try {
        if (type === 'category') {
          const isChild = xml.querySelector('CategoryList').children[0].children;
          if (isChild.length) {
            const parent = xml.querySelector('CategoryList').children[0];
            res.parentCategory = { 
              id: parent.getAttribute("propertyThemeId"),
              name: parent.getAttribute("Name")
            };
            res.name = decodeHtml(xml.querySelector('CategoryList').children[0].children[0].getAttribute("Name"));
          } else {
            res.name = decodeHtml(xml.querySelector('CategoryList Category').getAttribute("Name"));
          }

          // check to see if there are subcategories
          const subcategories = xml.querySelector('Sub').childNodes;
          if (subcategories.length > 0) {
            res.subcategories = [];
            subcategories.forEach(sub => {
              res.subcategories.push({ 
                id: sub.getAttribute('propertyThemeId'), 
                name: sub.getAttribute('Name') 
              });
            });
          }
        }

        const ids = [];
        for (let el of xml.getElementsByTagName('Property')) {
          ids.push(el.getAttribute('InstanceID'));
        }
        res.propertyIds = ids;
        resolve(res);

        } catch (reason) {
          reject('Error getting Property IDs: ' + reason);
        }

      })
      .catch(error => reject('Could not fetch Property IDs in category ' + id));
    });
  },

  getPropertyDetails(...args) {
    // convert args to an array
    const propertyIDs = args.flat();

    return new Promise((resolve, reject) => {
      parseXMLEndpoint(base + `Default.ashx?type=Details&value=Property:` + propertyIDs.join(','))
      .then(xml => {
        console.log(xml);
        try {
          const properties = [];
          for (let el of xml.getElementsByTagName('Property')) {
            const property = {};
            property.id = getAndDecodeAttribute(el, 'instanceId');
            property.title = getAndDecodeAttribute(el, 'Title');
            property.mediaId = el.getAttribute('SCMediaID');
            // contains the filename for a thumbnail video
            property.videoFileName = el.getAttribute('VideoFile');
            if (el.hasAttribute('About')) {
              property.about = getAndDecodeAttribute(el, 'About'); 
            }
            // vww://
            property.url = el.getAttribute('URL');
            // can be used to fetch Properties in that world
            property.worldId = el.getAttribute('WorldID');
            // display world name along with above link
            property.world = getAndDecodeAttribute(el, 'World');
            property.onlineCount = el.getAttribute('OnlineCount');
            // some Properties have an upcomming event
            if (el.hasAttribute('eventId')) {
              property.upcommingEvent = {};
              property.upcommingEvent.id = el.getAttribute('eventId');
              property.upcommingEvent.title = getAndDecodeAttribute(el, 'UpcomingEvent');
              property.upcommingEvent.localStartTime = el.getAttribute('UpcomingEventLocalStartTime');
              property.upcommingEvent.eventTime = el.getAttribute('UpcomingEventStartTime');
            }
            properties.push(property);
          }
          resolve(properties.sort((a, b) => b.onlineCount - a.onlineCount));
        } catch (error) {
          reject('Error parsing XML for Property details' + error)
        }
      })
      .catch(error => reject('Could not fetch Property details'));
    });
  },
}