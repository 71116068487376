import React, { Component } from 'react';

export default class LoadingIndicator extends Component {
  render() {
    // https://loading.io/css/
    return (
      <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    );
  }
}
