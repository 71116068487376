import React from 'react';
import { ReactComponent as UpDirectory } from './up-directory.svg';
import { ReactComponent as Arrow } from './arrow.svg';

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export default function ResultHeader(props) {
  const hasMore = props.propertyIds.length > props.page * props.perPage;
  const hasLess = props.page > 1;
  const totalPages = props.propertyIds.length / props.perPage;

  const parentSpan = props.parentCategory &&
    <React.Fragment>
    <span className="link" 
        onClick={ () => props.onQuery('category', props.parentCategory.id) }>
      { props.parentCategory.name }
    </span>
    { " : " }
    </React.Fragment>;

  return(
    <header>
      <h2>
        <span className="btn" aria-label="Back to home" role="img" 
          onClick={() => props.onQuery('categoryList')}
          >
          <UpDirectory />
        </span>
        <span className="link" onClick={() => props.onQuery('categoryList')}>
          Directory
        </span>
        { " : " }
        { parentSpan }
        <span className="link" onClick={() => props.onQuery('category', props.id)}>
        {props.name || 'Results'}
        </span>
        { props.type === 'search' &&
          <span className="search-text">{'Found ' + props.perPage * totalPages + ' results for "' + props.id + '"' }</span>
        }
      </h2>
      <div className="subcategories-and-pagination">
        { props.subcategories &&
          <p className="subcategories">
            { 
              props.subcategories.map(({id, name}) => {
                return(
                  <React.Fragment key={id}>
                  <span className="link" onClick={() => props.onQuery('category', id)}>
                    { decodeHtml(name) }
                  </span>
                  { " : " }
                  </React.Fragment>
                );
              })
            }
          </p>
        }
        { (hasMore || hasLess) &&
          <div className="page-navigation">
            { hasLess ? <span className="btn" role="img" aria-label="Back one page" onClick={() => props.onPaginate(-1)}> <Arrow class="arrow left" /> </span> : <div class="padding"></div> }
            <span>
              {`Page ${props.page} of ${Math.ceil(totalPages)}`}
            </span>
            { hasMore ? <span className="btn" role="img" aria-label="Forward one page" onClick={() => props.onPaginate(1)}> <Arrow class="arrow right" /> </span> : <div class="padding"></div> }
          </div>
        }
      </div>
    </header>
  );
}