import React from 'react';

function Category({ details, onQuery }) {
  if (details.subcategories.length > 5) {
    details.subcategories = details.subcategories.slice(0, 4);
    details.subcategories.push({ id: details.id, name: 'More...' });
  }

  const subSpans = details.subcategories.length > 0 &&
    details.subcategories.map(({id, name}, i) => {
    return(
        <li className="link subcategory" key={id} onClick={() => onQuery('category', id)}>
          { `${name.trim()}` }
          { i < details.subcategories.length - 1 && <span className="seperator">,</span> }
        </li>
    );
  });

  return(
    <li className={'category ' + details.name.toLowerCase().split(' ').join('')}>
      <h2 className="link" onClick={() => onQuery('category', details.id)}>
        { details.name }
      </h2>
      <ul className="subcategories">{ subSpans }</ul>
    </li>
  );
}

export default function CategoryList({ categories, onQuery }) {
  return(
    <ul className="categories">
      { categories.map(category => 
        <Category key={category.name} 
                  onQuery={onQuery} 
                  details={category} />)
      }
    </ul>
  );
}